import React, { Component } from "react"
import { Link } from 'gatsby';
// import AnchorLink from 'react-anchor-link-smooth-scroll';

import Layout from "../components/layout"
import SEO from "../components/seo"

import SimpleModal from '../components/utilities/SimpleModal';

import "./casestudymicrosoftnuance.scss"

import imgQuote from "../images/quotation_mark.webp"

export default ({ pageContext }) => (
    <Layout>
        <SEO
            title={pageContext.yoast.title}
            description={pageContext.yoast.metadesc}
            ogimg={pageContext.yoast.opengraph_image}
            link={pageContext.link}
        />
        <div className="case-study-mn-wrapper">
            <div className="container-fluid">
                <div className="row">
                    <div
                        className="header-container"
                        style={{
                            backgroundImage: `url(${pageContext.acf.header_image.csOneHedImaLocalFile.childImageSharp.fluid.src})`,
                        }}
                    >
                        <img src={imgQuote} alt="" />
                        <h2>{pageContext.acf.header_text}</h2>
                    </div>
                </div>
            </div>
            <div className="container detail-wrapper">
                <div className="row">
                    <div className="col-md-8">
                        <img
                            src={
                                pageContext.acf.client_logo.csOneCliLogLocalFile.childImageSharp
                                    .fluid.src
                            }
                            className="client-logo"
                            width={pageContext.acf.client_logo.media_details.width / 2.5}
                            height={pageContext.acf.client_logo.media_details.height / 2.5}
                            alt={pageContext.yoast.opengraph_title}
                        />
                    </div>
                    <div className="col-md-4">
                        <a href="https://www.meritmile.com/uploads/2022/11/microsoft-and-nuance-lead-generation-case-study.pdf" className="btn downloadBtn" target="_blank" rel="nofollow noopener noreferrer" style={{ marginTop: '4em'}}>Download the PDF here</a>
                    </div>
                    <div className="col-12">
                        <div className="bar"></div>
                    </div>
                    <div className="col-12">
                        <div
                            className="first-paragraph"
                            dangerouslySetInnerHTML={{
                                __html: pageContext.acf.first_paragraph,
                            }}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <h3>Challenge</h3>
                    </div>
                </div>
                <div className="row margin-b">
                    <div className="col-12">
                        <p>Microsoft and Nuance had made strategic investments in building their marketing and sales pipeline by partnering with industry-leading brands <b>HIMSS, Forrester, Reuters, and The Economist</b> to develop webinars focused on improving customer engagement and customer experiences. These highly credible and independent brands were bound to garner the attention of potential buyers, but a programmatic and predictable method for driving registrations and attendance was missing. Each of the webinars was associated with different and very specific market segments—company size, industry, GEO, and titles—which required a rifle, not a shotgun approach to driving demand. </p>
                    </div>
                </div>
                <div className="row margin-b">
                    <div className="col-12 text-center">
                        <img
                        src="https://www.meritmile.com/uploads/2022/09/microsoft-nuance-logos-stripe.png"
                        className="intro-image"
                        width=""
                        alt={pageContext.yoast.opengraph_title}
                        style={{ marginBottom: "2em", maxWidth: "100%" }}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <div
                            className="third-paragraph"
                            dangerouslySetInnerHTML={{
                                __html: pageContext.acf.third_paragraph,
                            }}
                        />
                    </div>
                    <div className="col-md-6">
                        <img
                            src="https://www.meritmile.com/uploads/2022/09/microsoft-nuance-solution-sample.jpg"
                            className="results-image img-fluid"
                            alt="Microsoft Nuance Case Study Solution "
                            width="750px"
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        &nbsp;
                    </div>
                    <div className="col-md-6">
                        <h3>Results</h3>
                    </div>
                </div>
                <div className="row margin-b">
                    <div className="col-md-6">
                        <img
                            src="https://www.meritmile.com/uploads/2022/09/microsoft-nuance-result-sample.jpg"
                            className="results-image img-fluid"
                            alt="Microsoft Nuance Case Study Results"
                            width="728px"
                        />
                    </div>
                    <div className="col-md-6">
                        <p>Once the campaign structure was ready and approved by Microsoft and Nuance, Merit Mile and our four partners ran the campaigns over three months. A benefit of working with a globally trusted demand generation partner is the ability to define guaranteed lead criteria and give our clients the confidence that they will receive the type and volume of leads they are expecting - without question. Throughout the life of the campaigns, Merit Mile handled the critical (and often overlooked) role of lead quality assurance, addressing any replacement leads (for example, a bad email address), weekly reporting, and managing client campaign dashboards. Without a partner like Merit Mile managing campaign execution, these time-consuming and detail-oriented responsibilities would fall on the client marketing manager.</p>
                    </div>
                </div>
                <div className="row margin-b">
                    <div className="col-12">
                        <p>On target, on budget, and on pace, Merit Mile delivered against the collective campaign goals, <b>yielding 2,000 qualified leads</b>. An additional 390 organic leads were also delivered, bringing the collective campaign total to 2,390 and counting. The campaigns successfully reached the desired senior level decision making audience, with a composite of C-level (11%), VP (16%), Director (35%), and Manager (34%) titles.</p>
                    </div>
                </div>
                <div className="row margin-b">
                    <div className="col-12 text-center">
                        <img
                        src="https://www.meritmile.com/uploads/2022/09/microsoft-nuance-piechart.jpg"
                        className="intro-image"
                        width=""
                        alt={pageContext.yoast.opengraph_title}
                        style={{ marginBottom: "2em", maxWidth: "100%" }}
                        />
                    </div>
                </div>
                <div className="row margin-b">
                    <div className="col-12">
                        <p>As the campaign was running, Microsoft and Nuance were able to feed the leads into a series of ongoing nurture streams for further engagement. Once the campaigns were complete, Merit Mile developed campaign summary and analysis reports, and conducted post-mortem sessions with the partners, Microsoft, and Nuance. </p>
                    </div>
                </div>
                <div className="row margin-b">
                    <div className="col-12">
                        <h3>Deliverables</h3>
                        <ul>
                            <li>Campaign strategy</li>
                            <li>Campaign execution and management</li>
                            <li>Creative services</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="fullWidthGrayCTA">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <h5>Learn more about how Merit Mile can support your vertical content marketing, lead generation, and sales goals by contacting <a href="mailto:Hello@MeritMile.com?subject=Microsoft%20Nuance%20Case%20Study%20Inquiry"><b style={{ color: "black" }}>hello@meritmile.com.</b></a></h5>
                        </div>
                    </div>
                </div>
            </div>
            <div className="yourteam-wrapper">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <h3>Your Merit Mile Team</h3>
                        </div>
                    </div>
                    <div className="row margin-b">
                        <div className="col-12">
                            <div className="team-cards">
                                <div className="team-card">
                                    <img
                                        src="https://www.meritmile.com/uploads/2022/08/sl-thumb.jpg"
                                        alt="Scott Lindars"
                                        className="img-fluid"
                                    />
                                    <h4>Scott Lindars</h4>
                                    <p>
                                        Director<br />
                                        Marketing +<br />
                                        Business Strategy
                                    </p>
                                </div>
                                <div className="team-card">
                                    <img
                                        src="https://www.meritmile.com/uploads/2022/08/sd-thumb.jpg"
                                        alt="Saul Darville"
                                        className="img-fluid"
                                    />
                                    <h4>Saul Darville</h4>
                                    <p>
                                        Executive<br />
                                        Creative<br />
                                        Director
                                    </p>
                                </div>
                                <div className="team-card">
                                    <img
                                        src="https://www.meritmile.com/uploads/2022/08/mc-thumb.jpg"
                                        alt="Mitch Curry"
                                        className="img-fluid"
                                    />
                                    <h4>Mitch Curry</h4>
                                    <p>
                                        Project<br />
                                        Manager<br />
                                        &nbsp;
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="fullWidthImageWrapper">
                <img
                    src="https://www.meritmile.com/uploads/2021/09/mm-divider.jpg"
                    alt="Merit Mile Icon Divider"
                    className="img-fluid"
                />
            </div>
            <div className="about-wrapper">
                <div className="container">
                    <div className="row">
                        <div className="col">
                            <h2>About Merit Mile</h2>
                        </div>
                    </div>
                    <div className="row margin-b">
                        <div className="col-md-6">
                            <p>Established in 2007 in Boca Raton, FL, Merit Mile is a marketing services provider focused on helping companies and corporate marketing departments build brands, drive sales revenue, and garner publicity in their respective industries. Our unique differentiator at Merit Mile is our ability to 100% own the message, the publicity, and the demand generation aspects of the corporate marketing function. Clients hire us and rehire us for our ability to refine their messaging/value proposition, develop top and mid-funnel leads, and get the type of editorial publicity that differentiates them from their competitors. From branding to PR to demand generation, Merit Mile is the high-octane fuel that invigorates corporate growth engines. To learn more, please email us at Hello@MeritMile.com, call us at 1-561-362-888, or visit us at www.MeritMile.com. </p>
                        </div>
                        <div className="col-md-6"><img className="img-fluid" src="https://www.meritmile.com/uploads/2021/06/mm-headquater.jpg" alt="Merit Mile Headquarter in Boca Raton, FL" /></div>
                    </div>
                </div>
            </div>
            <div className="container quote-wrapper">
                <div className="row">
                    <div className="col-12">
                        <p>
                            For more insights and to connect<br />
                            with Merit Mile <a href="mailto:Hello@MeritMile.com?subject=Citrix%20%20Google%20Contact%20Center%20Case%20Study%20Inquiry"><b style={{ color: "black" }}>email us here</b>
                            </a>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
)
